'use client'

import {
  googleSignin as googleSigninAction,
  signup as signupAction,
  signin as signinAction,
  verifySignin as verifySigninAction,
  signout as signoutAction,
  refreshAccessToken,
  activateProfile as activateProfileAction,
  resetPassword,
  resetPasswordRequest,
  ssoSigninAction,
} from '@/actions/auth'
import { getCurrentUser } from '@/actions/users'
import { ActionResponse, SignInActionResponse, User } from '@/lib/common/types'
import posthog from 'posthog-js'
import type { ReactNode } from 'react'
import { createContext, useEffect, useState } from 'react'
import { useInterval } from 'usehooks-ts'

type AuthContext = {
  loadingSignup: boolean
  loadingSignin: boolean
  isAuthenticated: boolean
  isAuthenticating: boolean
  currUser: User | undefined
  loadingCurrUser: boolean
  isSendingResetPasswordRequest: boolean
  isSendingResetPasswordSucceeded: boolean
  isResettingPassword: boolean
  isResettingPasswordSucceeded: boolean
  signup: (
    email: string,
    password: string,
    passwordConfirm: string,
    firstName: string,
    lastName: string,
  ) => Promise<ActionResponse>
  activateProfile: (
    email: string,
    otp_code: string,
  ) => Promise<ActionResponse & { isFirstLogin?: boolean }>
  signin: (email: string, password: string) => Promise<SignInActionResponse>
  googleSignin: (tokenId: string) => Promise<SignInActionResponse>
  ssoSignin: (organizationId: string) => Promise<ActionResponse>
  signout: () => void
  sendResetPasswordRequest: (email: string) => Promise<ActionResponse>
  sendResetPassword: (
    email: string,
    otp_code: string,
    password: string,
    password_confirm: string,
  ) => Promise<ActionResponse>
}

const AuthContext = createContext<AuthContext>({
  loadingSignup: false,
  loadingSignin: false,
  isAuthenticated: false,
  isAuthenticating: true,
  currUser: undefined,
  loadingCurrUser: false,
  isSendingResetPasswordRequest: false,
  isSendingResetPasswordSucceeded: false,
  isResettingPassword: false,
  isResettingPasswordSucceeded: false,
  signup: () => Promise.resolve({ ok: false }),
  signin: () => Promise.resolve({ ok: false, isFirstLogin: false }),
  googleSignin: () => Promise.resolve({ ok: false, isFirstLogin: false }),
  ssoSignin: () => Promise.resolve({ ok: false }),
  signout: () => {},
  activateProfile: () => Promise.resolve({ ok: false }),
  sendResetPasswordRequest: () => Promise.resolve({ ok: false }),
  sendResetPassword: () => Promise.resolve({ ok: false }),
})

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [loadingSignup, setloadingSignup] = useState<boolean>(false)
  const [loadingSignin, setloadingSignin] = useState<boolean>(false)
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(true)
  const [currUser, setCurrUser] = useState<User | undefined>()
  const [loadingCurrUser, setLoadingCurrUser] = useState<boolean>(false)
  const [isSendingResetPasswordRequest, setIsSendingResetPasswordRequest] =
    useState<boolean>(false)
  const [isSendingResetPasswordSucceeded, setIsSendingResetPasswordSucceeded] =
    useState<boolean>(false)
  const [isResettingPassword, setIsResettingPassword] = useState<boolean>(false)
  const [isResettingPasswordSucceeded, setIsResettingPasswordSucceeded] =
    useState<boolean>(false)

  useEffect(() => {
    if (!isAuthenticated) {
      setIsAuthenticating(true)
      verifySigninAction().then(async (res) => {
        if (res.ok) {
          setIsAuthenticated(true)
        }
        setIsAuthenticating(false)
      })
    }
    if (isAuthenticated && !currUser) {
      loadCurrentUser()
    }
  }, [isAuthenticated, currUser])

  useInterval(
    async () => {
      if (isAuthenticated) {
        const refreshRes = await refreshAccessToken()
        if (!refreshRes?.ok) {
          setIsAuthenticated(false)
        }
      }
    },
    1000 * 60 * 5,
  )

  useInterval(async () => {
    if (isAuthenticated) {
      const verifyRes = await verifySigninAction()
      if (!verifyRes?.ok) {
        setIsAuthenticated(false)
      }
    }
    if (isAuthenticated && !currUser) {
      loadCurrentUser()
    }
  }, 1000 * 30)

  const signup = async (
    email: string,
    password: string,
    passwordConfirm: string,
    firstName: string,
    lastName: string,
  ) => {
    setloadingSignup(true)
    const res = await signupAction(
      email,
      password,
      passwordConfirm,
      firstName,
      lastName,
    )
    setloadingSignup(false)
    if (res.ok) {
      const signInRes = await signin(email, password)
      localStorage.setItem('isFirstLogin', signInRes.isFirstLogin.toString())
    }
    return res
  }

  const activateProfile = async (email: string, otp_code: string) => {
    setloadingSignup(true)
    const res = await activateProfileAction(email, otp_code)
    setloadingSignup(false)
    if (res.ok) {
      localStorage.setItem(
        'isFirstLogin',
        res?.isFirstLogin?.toString() as string,
      )
    }
    return res
  }

  const signin = async (email: string, password: string) => {
    setloadingSignin(true)
    setIsAuthenticating(true)
    const res = await signinAction(email, password)
    setloadingSignin(false)

    if (res.ok) {
      setIsAuthenticated(true)
    } else {
      setIsAuthenticated(false)
    }
    setIsAuthenticating(false)

    return res
  }

  const googleSignin = async (accessToken: string) => {
    setloadingSignin(true)
    setIsAuthenticating(true)
    const res = await googleSigninAction(accessToken)
    setloadingSignin(false)

    if (res.ok) {
      localStorage.setItem('isFirstLogin', res.isFirstLogin.toString())
      setIsAuthenticated(true)
    } else {
      setIsAuthenticated(false)
    }
    setIsAuthenticating(false)

    return res
  }

  const loadCurrentUser = async () => {
    setLoadingCurrUser(true)
    const res = await getCurrentUser()
    const user = await res.payload
    setLoadingCurrUser(false)

    if (res.ok && user) {
      posthog.identify(user.id.toString(), {
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
      })
      setCurrUser(user)
    } else {
      setCurrUser(undefined)
    }
  }

  const signout = async () => {
    setIsAuthenticating(true)
    const res = await signoutAction()

    if (res.ok) {
      posthog.reset()
      setIsAuthenticated(false)
      setCurrUser(undefined)
    }
    setIsAuthenticating(false)
  }

  const sendResetPasswordRequest = async (email: string) => {
    setIsSendingResetPasswordRequest(true)
    const res = await resetPasswordRequest(email)

    if (res.ok) {
      setIsSendingResetPasswordSucceeded(true)
    }
    setIsSendingResetPasswordRequest(false)

    return res
  }

  const sendResetPassword = async (
    email: string,
    otp_code: string,
    password: string,
    password_confirm: string,
  ) => {
    setIsResettingPassword(true)
    const res = await resetPassword(email, otp_code, password, password_confirm)

    if (res.ok) {
      setIsResettingPasswordSucceeded(true)
    }
    setIsResettingPassword(false)

    return res
  }

  const ssoSignin = async (organizationId: string) => {
    setloadingSignin(true)
    setIsAuthenticating(true)
    const res = await ssoSigninAction(organizationId)
    setloadingSignin(false)

    if (res.ok) {
      setIsAuthenticated(true)
    } else {
      setIsAuthenticated(false)
    }
    setIsAuthenticating(false)

    return res
  }

  return (
    <AuthContext.Provider
      value={{
        loadingSignup,
        loadingSignin,
        isAuthenticated,
        isAuthenticating,
        currUser,
        loadingCurrUser,
        isSendingResetPasswordRequest,
        isSendingResetPasswordSucceeded,
        isResettingPassword,
        isResettingPasswordSucceeded,
        signup,
        signin,
        signout,
        googleSignin,
        ssoSignin,
        activateProfile,
        sendResetPasswordRequest,
        sendResetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext
