'use client'

import React, { createContext, useContext, useEffect, useState } from 'react'
import { hasFeatureAccess } from '@/actions/billing'
import { toast } from 'sonner'
import { useOrganization } from '@/contexts/OrganizationProvider'
import { FeatureAccessType, ALL_GATED_FEATURES } from '@/lib/common/types'
import { MAIN_APP_TYPE } from '@/middleware'
import useAuth from '@/hooks/useAuth'

export const FeatureAccessContext = createContext<
  FeatureAccessType | undefined
>(undefined)

export const useFeatureAccess = () => {
  const context = useContext(FeatureAccessContext)
  if (!context) {
    throw new Error(
      'useFeatureAccess must be used within a FeatureAccessProvider',
    )
  }
  return context
}

export function FeatureAccessProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const [featureAccess, setFeatureAccess] = useState<FeatureAccessType>({})
  const { isAuthenticated } = useAuth()
  const { currentOrganization } = useOrganization()

  useEffect(() => {
    if (!isAuthenticated) {
      return
    }

    const fetchFeatureAccess = async () => {
      const orgID = currentOrganization ? currentOrganization.id : null
      const response = await hasFeatureAccess(ALL_GATED_FEATURES, orgID)
      if (!response.ok && response.error) {
        // TODO : This is something we need to warn internally
        // toast.error('Failed to fetch feature access', {
        //   description: response.error,
        // })
        return
      }

      setFeatureAccess((await response.payload) || {})
    }
    fetchFeatureAccess()
  }, [currentOrganization, isAuthenticated])

  return (
    <FeatureAccessContext.Provider value={featureAccess}>
      {children}
    </FeatureAccessContext.Provider>
  )
}
