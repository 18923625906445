'use client'

import { listOrganizations } from '@/actions/organizations'
import useAuth from '@/hooks/useAuth'
import { Organization } from '@/lib/common/types'
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
  useMemo,
} from 'react'

interface OrganizationContextType {
  organizationId: string | undefined
  setOrganizationId: (id: string | undefined) => void
  organizations: Organization[]
  currentOrganization: Organization | undefined
  addOrganization: (organization: Organization) => void
  areOrganizationsFetched: boolean
}

export const OrganizationContext = createContext<
  OrganizationContextType | undefined
>(undefined)

export const useOrganization = () => {
  const context = useContext(OrganizationContext)
  if (!context) {
    throw new Error(
      'useOrganization must be used within an OrganizationProvider',
    )
  }
  return context
}

interface OrganizationProviderProps {
  children: ReactNode
}

export const OrganizationProvider: React.FC<OrganizationProviderProps> = ({
  children,
}) => {
  const { currUser } = useAuth()
  const [organizations, setOrganizations] = useState<Organization[]>([])
  const [organizationId, setOrganizationId] = useState<string | undefined>()
  const [areOrganizationsFetched, setAreOrganizationsFetched] = useState(false)

  const currentOrganization = useMemo(() => {
    return organizations.find((org) => org.id === organizationId)
  }, [organizations, organizationId])

  const addOrganization = (organization: Organization) => {
    if (!organizations.some((org) => org.id === organization.id)) {
      setOrganizations([...organizations, organization])
    }
  }

  useEffect(() => {
    const fetchOrganizations = async () => {
      const response = await listOrganizations()
      if (response.ok) {
        setOrganizations((await response.payload) || [])
      }
      setAreOrganizationsFetched(true)
    }

    if (currUser) {
      fetchOrganizations()
    }
  }, [currUser])

  useEffect(() => {
    if (currUser) {
      const storedOrgId = localStorage.getItem(`organizationId_${currUser.id}`)
      if (storedOrgId) {
        setOrganizationId(storedOrgId)
      }
    }
  }, [currUser])

  const setAndPersistOrganizationId = (id: string | undefined) => {
    setOrganizationId(id)
    if (currUser && currUser.id) {
      if (id) {
        localStorage.setItem(`organizationId_${currUser.id}`, id)
      } else {
        localStorage.removeItem(`organizationId_${currUser.id}`)
      }
    }
  }

  return (
    <OrganizationContext.Provider
      value={{
        organizationId,
        setOrganizationId: setAndPersistOrganizationId,
        organizations,
        currentOrganization,
        addOrganization,
        areOrganizationsFetched,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  )
}
